<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text color="success" v-on="on">
              <v-icon size="25" dark>mdi-bell-outline</v-icon>
              <v-badge
                bordered
                content="3"
                overlap
                style="margin: 5px 10px 0px 10px"
              ></v-badge>
            </v-btn>
          </template>
          <span>Request Response Notification</span>
        </v-tooltip> -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text color="primary" v-on="on" @click="refresh">
              <v-icon dark>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card outlined>
        <v-card-text>
          <v-data-table
            :loading="loading"
            loader-height="1"
            :headers="headers"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="appointmentlist"
            :items-per-page="10"
          >
            <template v-slot:item.subscriberName="{ item }">
              {{ item.subscriberName | capitalize }}
            </template>
            <template v-slot:item.date_start="{ item }">
              {{ item.date_start | myDate }} @ {{ item.time_start | myTime }}
            </template>
            <template v-slot:item.time_start="{ item }">
              {{ item.time_start | myTime }}
            </template>
            <template v-slot:item.time_end="{ item }">
              {{ item.time_end | myTime }}
            </template>

            <template v-slot:item.accepted="{ item }">
              <v-icon :color="item.accepted ? 'primary' : 'error'">
                {{ item.accepted ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </template>

            <template v-slot:item.done="{ item }">
              <v-icon :color="item.done ? 'primary' : 'error'">
                {{ item.done ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </template>

            <template v-slot:item.id="{ item }">
              <v-badge bordered color="primary" icon="mdi-arrow-right" overlap>
                <v-btn
                  :disabled="!checkDisabled(item.done)"
                  color="primary"
                  style="padding-right: 18px"
                  x-small
                  :to="{
                    name: 'patient.history.detail',
                    params: {
                      id: _encode(item.id),
                      subscriber: _encode(item.subscriberName),
                      date: _encode(item.date_start),
                    },
                  }"
                >
                  detail
                </v-btn>
              </v-badge>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
// import AppointmentDetail from "../../components/appointments/schedules/detail.vue";
// import RequestUpdate from "../../components/appointments/request/update.vue";
// import RequestRemove from "../../components/appointments/request/deletion.vue";
import Util from "../../mixins/utils";
export default {
  // components: { AppointmentDetail, RequestUpdate, RequestRemove },
  mixins: [Util, WinScroll("position")],
  data() {
    return {
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "patient.dash" },
        },
        {
          exact: true,
          link: true,
          text: "Past Appointment",
          to: { name: "patient.history" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      detailDialog: {},
      removeDialog: {},
      appointmentlist: [],
      updateDialog: {},
      disabled: false,
      loading: false,
      headers: [
        { text: "provider", value: "subscriberName" },
        { text: "date", value: "date_start" },
        { text: "meeting location", value: "meeting_location" },
        { text: "accepted", value: "accepted", align: "center" },
        { text: "done", value: "done", align: "center" },
        { text: "actions", value: "id", align: "center" },
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.loading = true;
      let self = this;
      Restful.medics.patient
        .past()
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.appointmentlist = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    refresh() {
      this.list();
    },
    closeDetail(p) {
      this.$set(this.detailDialog, p.id, p.state);
    },
    menulink() {
      this.$emit("sidebar");
    },
    closeUpdate(p) {
      this.$set(this.updateDialog, p.id, p.state);
    },
    submitUpdate(p) {
      this.snack.bar = true;
      console.log(p);
      Restful.medics.patient.request
        .update(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.updateDialog, p.id, p.state);
    },
    checkDisabled(a) {
      if (a === true) {
        return true;
      } else {
        return false;
      }
    },
    closeDelete(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitDelete(p) {
      this.snack.bar = true;
      console.log("id:", p.id);
      console.log("medic:", p.medic);
      // delete(availaid, reqid)
      Restful.medics.patient.request
        .delete(p.medic, p.id)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
  },
};
</script>
